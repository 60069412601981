import React from "react";
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from "../link";
import styled from 'styled-components'

const ExpanderButton = styled.button`
  border:none;
  background:none;
  color: #27203B;
  cursor:pointer;
`

const TreeNode = ({ className = '', url, title, items, level }) => {
  const [isCollapsed, setCollapsed] = React.useState(level >= config.sidebar.autoExpandedLevels)
  const collapse = () => setCollapsed(!isCollapsed)

  const hasChildren = items.length !== 0;
  let location;
  if (typeof (document) != 'undefined') {
    location = document.location;
  }
  const active =
    location && (location.pathname === url || location.pathname === (config.gatsby.pathPrefix + url));
  const calculatedClassName = `${className} item level-${level} ${active ? 'active' : ''}`;

  const component = url ?
    <Link className="toc-item" to={url}>{title}</Link>
    : title ?
      config.sidebar.forcedExpandedLevels <= level ?
        <ExpanderButton className="toc-heading" onClick={collapse} aria-label='collapse'>
          {title}
          <span className='collapser'>
            {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
          </span>
        </ExpanderButton> :
        <span className="toc-heading">{title}</span> :
      null

  return (
    <li className={calculatedClassName}>
      {component}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              level={level + 1}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
}
export default TreeNode
