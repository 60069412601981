import React, { useState } from 'react';
import TreeNode from './treeNode';

const calculateTreeData = nodes => {
  const treeRoot = {
    items: []
  }

  for (let { fields: { slug, path, title } } of nodes) {
    if (slug === '/') continue

    const insert = (tree, [part, ...rest]) => {
      const isLeafNode = rest.length === 0
      if (isLeafNode)
        tree.items.push({ title, url: slug, items:[] })
      else {
        let item = tree.items.find(i => i.title === part)
        if (!item) {
          item = { title: part, items: [] }
          tree.items.push(item)
        }
        insert(item, rest)
      }
    }

    insert(treeRoot, path, slug, title)
  }
  return treeRoot
}

const Tree = ({ edges }) => {
  let [treeData] = useState(() => {
    return calculateTreeData(edges);
  });

  return (
    <TreeNode
      className={`firstLevel`}
      level={0}
      {...treeData}
    />
  );
}

export default Tree
