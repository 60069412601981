import React, {useState, useEffect} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from './link';
import './styles.css';
import './header.css';
import config from '../../config.js';

import Loadable from 'react-loadable';
import LoadingProvider from './mdxComponents/loading';

const help = require('./images/help.svg');
const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];
if(isSearchEnabled && config.header.search.indexName) {
  searchIndices.push(
    { name: `${config.header.search.indexName}`, title: `Results`, hitComp: `PageHit` },
  );
}

import Sidebar from "./sidebar";

const LoadableComponent = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});

function myFunction() {
  var x = document.getElementById("navbar");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}

const NetworkStats = Object.freeze({
  'IDLE': 0,
  'FETCHING': 1,
  'FETCHED': 2,
  'FETCH_ERROR': 3
})

function useFetch(url) {
  const initialState = {
    status:  NetworkStats.IDLE,
    error: null,
    data: null
  }

  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case NetworkStats.FETCHING:
        return {...initialState, status: NetworkStats.FETCHING}
      case NetworkStats.FETCHED:
        return {...initialState, status: NetworkStats.FETCHED, data: action.payload}
      case NetworkStats.FETCH_ERROR:
        return {...initialState, status: NetworkStats.FETCH_ERROR, data: action.payload}
      default:
        return state
    }
  }, initialState)

  React.useEffect(() => {
    if (!url) return

    const fetchData = async() => {
      dispatch({type: NetworkStats.FETCHING})

      try {
        const response = await fetch(url)
        const data = await response.json()
        dispatch({type: NetworkStats.FETCHED, payload: data})
      } catch (error) {
        dispatch({type: NetworkStats.FETCH_ERROR, payload: error.message})
      }
    }

    fetchData()
  }, [url])

  return state
}

const Header = ({location}) => {
  const [url, setUrl] = React.useState(null)
  const [versionFromUri, setVersionFromUri] = useState("");

  const { status, data, error } = useFetch(url);

  useEffect(() => {
    setUrl(`${window.location.origin}/manifest.json`)
    setVersionFromUri(window.location.pathname.split("/")[1])
  }, []);

  if (error) console.error(error)
  const versions = data && data.versions.stable

  return (<StaticQuery
    query={
      graphql`
        query headerTitleQuery {
          site {
            siteMetadata {
              headerTitle
              logo {
                link
                image
              }
              headerLinks {
                link
                text,
                current
              }
            }
          }
        }
        `}
    render={(data) => {
      const {
        site: {
          siteMetadata: {
            headerTitle,
            logo,
            headerLinks,
          }
        }
      } = data;
      return (
        <div className={'navBarWrapper'}>
          <nav className={'navBarDefault'}>
            <div className={'navBarHeader'}>
              <Link to={logo.link} className={'navBarBrand'}>
                <img className={'img-responsive displayInline'} src={`${logo.image}`} alt={'Hadean'} />
              </Link>
              <span onClick={myFunction} className={'navBarToggle'}>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
              </span>
            </div>
            {isSearchEnabled ? (
              <div className={'searchWrapper hiddenMobile navBarUL'}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
              ): null}
            <div id="navbar" className={'topnav'}>
              <div className={'visibleMobile'}>
                <Sidebar location={location} />
                <hr/>
                {isSearchEnabled ? (
                  <div className={'searchWrapper'}>
                    <LoadableComponent collapse={true} indices={searchIndices} />
                  </div>
                  ): null}
              </div>
              <ul className={'navBarUL navBarNav navBarULRight'}>
                {headerLinks.map((link, key) => {
                  if(link.link !== '' && link.text !== '') {
                    return(
                      <li key={key} className={link.current ? 'current' : ''}>
                        <a className="sidebarLink" href={link.link}>{link.text}</a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className={'navBarRight'}>
                  <label for="versionSelector">Version</label>
                  <select id="versionSelector" onChange={(e) => {window.location=`${window.location.origin}/${e.target.value}`}}>
                {
                  status === NetworkStats.FETCHED && versions.map((version, index) => {
                    return version == versionFromUri
                      ? <option key={index} value={version} selected>{version}</option>
                      : <option key={index} value={version}>{version}</option>
                  })
                }
              </select>
            </div>
          </nav>
        </div>
      );
    }}
  />)
};

export default Header;
