import React from "react";
import CodeBlock from "./codeBlock";
import '../styles.css';
import getAnchor from "../getAnchor"
import Link from "../link"


const getId = props => 
    typeof props.children === "string" ? 
      getAnchor(props.children) :
      ""

export default {
  h1: props => <h1 className='heading1' id={getId(props)} {...props} />,
  h2: props => <h2 className='heading2' id={getId(props)} {...props} />,
  h3: props => <h3 className='heading3' id={getId(props)} {...props} />,
  h4: props => <h4 className='heading4' id={getId(props)} {...props} />,
  h5: props => <h5 className='heading5' id={getId(props)} {...props} />,
  h6: props => <h6 className='heading6' id={getId(props)} {...props} />,
  p: props => <p className='paragraph' {...props} />,
  pre: props => <pre className='pre' {...props} />,
  code: CodeBlock,
  Link: Link
};
