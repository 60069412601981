const config = {
	"gatsby": {
		"pathPrefix": "/v2.7",
		"siteUrl": "https://docs.hadean.com",
		"gaTrackingId": "UA-56697550-5",
		"trailingSlash": false
	},
	"header": {
		"logo": "/v2.7/hadean-logo.svg",
		"logoLink": "https://www.hadean.com",
		"title": "Aether Engine",
		"links": [
			{ "text": "Guide", "link": "/", current: true },
			{ "text": "Aether Engine API", "link": "/v2.7/aether-engine-api/" },
			{ "text": "Unity API", "link": "/v2.7/unity-api/" },
		],
		"search": {
			"enabled": false,
			"indexName": "",
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
		],
    	"collapsedNav": [
    	],
		"links": [
			{ "text": "Hadean", "link": "https://www.hadean.com"},
			{ "text": "Privacy policy", "link": "https://www.hadean.com/terms/privacy-policy"},
		],
		"ignoreIndex": true,
		"ignorePathLevels": 1,
		"autoExpandedLevels": 1,
		"forcedExpandedLevels": 0
	},
	"siteMetadata": {
		"title": "Aether Engine documentation",
		"description": "Aether Engine documentation",
		"ogImage": null,
		"favicon": "/v2.7/square-logo.png"
	}
};

module.exports = config;
